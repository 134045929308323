<template>
<div id="tips-add-games-table">
      <Loader v-if="loading" rows="20" cols="5"></Loader>
      <Alert v-else-if="games.length <= 0" variant="info" >Här fanns inga matcher att visa just nu!</Alert>
      <div v-else>
            <b-row v-if="!smallview" no-gutters class="mt-2 mb-4">
                  <b-col cols="12" class="mb-1">
                        <b-select v-model="display">
                              <b-select-option value="1">Matchdatum</b-select-option>
                              <b-select-option value="2">Grupp</b-select-option>
                        </b-select>
                  </b-col>
            </b-row>
            <div v-for="(sgames, index) in games" :key="index" class="mb-5">
                  <b-row class="font-weight-bold my-2 py-2 tips-add-grouped-row border-bottom font-large" no-gutters>
                        <b-col v-if="display == 1" cols="12">
                              {{titleDate(sgames[0])}}
                        </b-col>
                        <b-col v-else-if="display == 2" cols="12">
                              {{sgames[0].group.name}}
                        </b-col>
                  </b-row>
                  <b-row no-gutters class="tips-view-header">
                        <b-col cols="1" md="2" class="text-left">
                              <label class="d-none d-md-inline">Match</label>
                              <label class="d-inline d-md-none">M</label>
                        </b-col>
                        <b-col cols="3" class="text-left"><label class="d-none d-sm-inline">Hemma</label>
                              <label class="d-inline d-sm-none">Hemma</label>
                        </b-col>
                        <b-col cols="3" md="2" class="text-center"><label class="d-none d-sm-inline">Resultat</label>
                              <label class="d-inline d-sm-none">R</label>
                        </b-col>
                        <b-col cols="3" class="text-right"><label class="d-none d-sm-inline">Borta</label>
                              <label class="d-inline d-sm-none">Borta</label>
                        </b-col>
                        <b-col cols="2" class="text-center">
                              1X2
                        </b-col>
                  </b-row>
                  <b-row no-gutters v-for="g in sgames" :key="g.id" class="tips-add-row " :class="gameRowClasses(g)">
                        <b-col cols="12" class="pb-2 border-bottom">
                              <b-row class=" tips-add-game-row">
                                    <b-col cols="1" md="2">
                                          <label class="d-none d-md-inline">{{fulldate(g)}}</label>
                                          <label class="d-inline d-md-none font-small">{{shortdate(g)}}</label>
                                    </b-col>
                                    <b-col v-if="g.hometeamid > 0" cols="3" class="groups-team" @click="GoTo(g.hometeamid)">
                                          <Team :team="team(g.hometeamid)"></Team>
                                    </b-col>
                                    <b-col cols="3" v-else class="groups-emtpy-team">TBD</b-col>
                                    <b-col cols="3" md="2" class="text-center groups-team" @click="ViewTipsGame(g)">
                                          <div v-if="gameOpen(g)">
                                                <countdown :left-time="timeleft(g)" @finish="Finished(g)">
                                                      <span slot="process" slot-scope="{ timeObj }">
                                                            <div v-if="timeObj.d <= 0">
                                                                  {{ timeObj.h }}:{{ timeObj.m }}:{{ timeObj.s }}
                                                            </div>
                                                            <div v-else-if="timeObj.d == 1">
                                                                  {{timeObj.d}} dag
                                                            </div>
                                                            <div v-else>
                                                                  {{timeObj.d}} dagar
                                                            </div>
                                                      </span>
                                                      <span slot="finish">
                                                            Stängd
                                                      </span>
                                                </countdown>
                                          </div>
                                          <b-row no-gutters v-else>
                                                <b-col cols="4" class="text-center">
                                                      {{g.homescore}}
                                                </b-col>
                                                <b-col cols="4" class="text-center">
                                                      -
                                                </b-col>
                                                <b-col cols="4" class="text-center">
                                                      {{g.awayscore}}
                                                </b-col>
                                          </b-row>
                                    </b-col>
                                    <b-col v-if="g.awayteamid > 0" cols="3" class="text-right groups-team" @click="GoTo(g.awayteamid)">
                                          <Team :team="team(g.awayteamid)" away></Team>
                                    </b-col>
                                    <b-col cols="3" v-else class="groups-emtpy-team">TBD</b-col>
                                    <b-col cols="2" class="text-center">
                                          <span v-if="gameOpen(g)">
                                                <inline-svg @click="ShowAVG(g)" title="Se genomsnitt av andras tips" class="pointer" :src="$images.svg('common/info.svg')" width="24" height="24" />
                                          </span>
                                          <span v-else>{{g.result}}</span>
                                    </b-col>
                                    <b-col cols="1" md="2">
                                          <label class="d-none d-md-inline">
                                                <span class="mr-1">{{g.group.name}}</span>
                                                <span> #{{g.gamenr}}</span>
                                          </label>
                                          <label class="d-inline d-md-none font-small">{{time(g)}}</label>
                                    </b-col>
                                    <b-col cols="4" md="3">
                                          <b-form-select :id="'ginput_homescore_'+g.id" v-if="gameOpen(g)" :value="getUserAnswer(g.id, 'homescore')" @change="SaveTips(g.id,'homescore', $event)">
                                                <b-form-select-option v-for="index in nrofgoals" :key="index" :value="index">{{index}}</b-form-select-option>
                                          </b-form-select>
                                          <span v-else v-html="closedHtml(g, 'homescore')"></span>
                                    </b-col>
                                    <b-col cols="1" md="2" class="text-center" @click="GoToVS(g)">
                                          <b-link>VS</b-link>
                                    </b-col>
                                    <b-col cols="4" md="3">
                                          <b-form-select :id="'ginput_awayscore_'+g.id" v-if="gameOpen(g)" :value="getUserAnswer(g.id, 'awayscore')" @change="SaveTips(g.id,'awayscore', $event)">
                                                <b-form-select-option v-for="index in nrofgoals" :key="index" :value="index">{{index}}</b-form-select-option>
                                          </b-form-select>
                                          <span v-else v-html="closedHtml(g, 'awayscore')"></span>
                                    </b-col>
                                    <b-col cols="2">
                                          <b-form-select :id="'ginput_bet_'+g.id" v-if="gameOpen(g)" :value="getUserAnswer(g.id, 'bet', true)" @change="SaveTips(g.id,'bet', $event)">
                                                <b-form-select-option value=""></b-form-select-option>
                                                <b-form-select-option value="1">1</b-form-select-option>
                                                <b-form-select-option value="0">X</b-form-select-option>
                                                <b-form-select-option value="2">2</b-form-select-option>
                                          </b-form-select>
                                          <span v-else v-html="closedHtml(g, 'bet')"></span>
                                    </b-col>
                              </b-row>
                        </b-col>
                  </b-row>

            </div>

            <b-modal ref="AVGModal" size="lg" v-model="showavg" @hide="hideAVG()" title="Genomsnitt baserat på andra deltagares tips" hide-footer>
                  <GAVG :avg="currentAVG" v-on:close="hideAVG" v-on:use="UseAVG"></GAVG>
            </b-modal>
      </div>
</div>
</template>

<script>
const Alert = () => import("@/components/miscellaneous/Alert");
const Loader = () => import("@/components/miscellaneous/Loader");
const Team = () => import("@/components/team/Team");
const GAVG = () => import("@/components/tips/add/games/GameAVGTips");

export default {
      name: "AddGamesTable",
      props: {
            "smallview": Boolean,
            "smallgames": Array
      },
      components: {
            Alert,
            Loader,
            Team,
            GAVG
      },
      data() {
            return {
                  display: 1,
                  loadscroll: false,
                  currentAVG: false,
                  showavg: false
            };
      },
      computed: {
            loading() {
                  return this.$store.getters["tipsg/mygamesloading"] ||
                        this.$store.getters["tournamentsg/tournamentusersloading"];
            },
            games() {
                  if (this.smallview && this.smallgames && this.smallgames.length > 0) {
                        return [this.smallgames];
                  }

                  var key = "gameday";
                  var sortkey = "gamedate";
                  if (this.display == 2) {
                        key = "groupid";
                        sortkey = false;
                  }
                  var data = this.$store.getters["tipsg/mygames"];
                  if (data && data.games.length > 0) {
                        if (!sortkey)
                              sortkey = key;
                        var games = data.games;
                        if (!games)
                              games = [];
                        var sorted = this.$functions.sort(games, sortkey);

                        games = this.$functions.groupGamesByKey(sorted, key);

                        return games;
                  }
                  return [];
            },
            sport(){
                  return this.$store.getters["tournamentsg/tournament"].sport;
            },
            nrofgoals() {
                  var max = 20;
                  if(this.sport == "1") // fotboll
                        max = 10;
                  else if(this.sport == "2") // ishockey
                        max = 20;
                  else if(this.sport == "3") // handboll
                        max = 60;
                  var goals = [""];
                  for (var i = 0; i <= max; i++) {
                        goals.push(i);
                  }
                  return goals;
            },
            userAnswers() {
                  var data = this.$store.getters["tipsg/mygames"];
                  if (data) {
                        return data.answers;
                  }
                  return [];
            }
      },
      methods: {
            team(tid) {
                  return this.$functions.getTeam(tid);
            },
            gameOpen(g) {
                  let isopen = this.$date.isOpen(g.gamedate);
                  return isopen;
            },
            titleDate(g) {
                  return this.$date.stringToReadableFormat(g.gamedate);
            },
            shortdate(g) {
                  return this.$date.custom(g.gamedate, "DD MMM").toLowerCase();
            },
            fulldate(g) {
                  return this.$date.TeamGroupDate(g.gamedate);
            },
            time(g) {
                  return this.$date.GameTime(g.gamedate);
            },
            bet(result) {
                  return result === "0" ? "X" : (result ? result : "&nbsp;");
            },
            UserAnswerClass(game, result) {
                  var ua = this.getUserAnswer(game.id, result);
                  if (ua == "&nbsp;" || ua == "" || this.gameOpen(game)) {
                        return "";
                  } else if (result == "bet") {
                        if (ua === this.bet(game.result))
                              return "true";
                        return "false";
                  } else {
                        if (ua === game[result])
                              return "true";
                        return "false";
                  }
            },
            getUserAnswer(gameid, rtype, rawansw) {
                  var answers = this.userAnswers;
                  if (answers && answers[gameid]) {
                        if (rtype == "bet" && !rawansw) {
                              return this.bet(answers[gameid][rtype]);
                        }
                        return answers[gameid][rtype];
                  }
                  return "&nbsp;";
            },
            dateAsId(date) {
                  return this.$date.dateAsId(date);
            },
            gameRowClasses(g) {
                  var classes = this.dateAsId(g.gamedate);
                  if (!this.gameOpen(g)) {
                        classes += " text-muted";
                  } else classes += " tips-open";
                  return classes;
            },
            FixPageScroll() {
                  var topPos = 0;
                  if (this.isSingleView) {
                        var userrow = document.getElementsByClassName("current-user-row");
                        if (userrow.length > 0) {
                              topPos = this.$functions.position(userrow[0]).top - 100;
                        }
                  } else {
                        var dateid = this.dateAsId(this.$date.dateNowWithoutTime());
                        var dateelements = document.getElementsByClassName(dateid);
                        if (dateelements.length > 0) {
                              topPos = this.$functions.position(dateelements[0]).top - 50;

                        } else {
                              // finns nästa omgångs?
                              var openelements = document.getElementsByClassName("tips-open");
                              if (openelements.length > 0) {
                                    topPos = this.$functions.position(openelements[0]).top - 50;
                              }
                        }
                  }
                  if (topPos > 0)
                        window.scrollTo(0, topPos);
            },
            timeleft(g) {
                  return this.$date.TimeLeft(g.gamedate);
            },
            Finished(g, rtype) {
                  var id = "g_" + g.id;
                  var div = document.getElementById(id);
                  var ans = this.closedHtml(g, rtype);
                  div.innerHTML = ans;
            },
            ShowToast(title, text, variant) {
                  this.$bvToast.toast(text, {
                        title: title,
                        variant: variant,
                         autoHideDelay: 1500,
                        solid: true,
                  });
            },
            closedHtml(g, rtype) {
                  var extraClass = this.UserAnswerClass(g, rtype);
                  var ans = this.getUserAnswer(g.id, rtype);
                  var div = "<div class='text-center tips-add-entry " + extraClass + "'>" + ans + "</div>";
                  return div;
            },
            SaveTips(gid, rtype, value, skiptoast) {
                  var postdata = {
                        "gameid": gid,
                        "tipstype": rtype,
                        "value": value
                  };
                  let input = document.getElementById("ginput_" + rtype + "_" + gid);
                  this.$store.dispatch("tipsp/SaveTips", postdata).then((response) => {
                        if (response.data.status == "ok") {
                              // sparat
                              if (!skiptoast)
                                    this.ShowToast("Sparat", "Ditt tips har sparats!", "success");
                              input.classList.remove("bg-danger");
                              if (rtype == "homescore" ||
                                    rtype == "awayscore") {
                                    // fixa 1x2
                                    this.FixBetTips(gid);
                              }
                        } else {
                              // error 
                              var error = response.data.errors.join(", ");
                              this.ShowToast("Fel!", error, "danger");
                              var uans = this.getUserAnswer(gid, rtype);
                              input.classList.add("bg-danger");
                              input.value = uans;
                        }
                        this.$store.dispatch("tipsg/getAnswered");
                  });
            },
            FixBetTips(gid) {
                  let bet = "";
                  var homescore = document.getElementById("ginput_homescore_" + gid).value;
                  var awayscore = document.getElementById("ginput_awayscore_" + gid).value;
                  if (homescore > awayscore) // 1            
                        bet = "1";
                  else if (awayscore > homescore)
                        bet = "2";
                  else if (homescore == awayscore && homescore != undefined && homescore != "")
                        bet = "0";
                  document.getElementById("ginput_bet_" + gid).value = bet;
                  this.SaveTips(gid, "bet", bet, true);
            },
            ShowAVG(g) {
                  this.showavg = true;
                  this.$store.dispatch("tipsg/getTipsAVG", g.id).then((response) => {
                        this.currentAVG = {
                              game: g,
                              avg: response
                        };
                  });
            },
            UseAVG(game) {
                  const e = new Event("change");
                  var gid = game.id;
                  var hs = document.getElementById("ginput_homescore_" + gid);
                  hs.value = game.homescore;
                  var as = document.getElementById("ginput_awayscore_" + gid);
                  as.value = game.awayscore;
                  var bet = document.getElementById("ginput_bet_" + gid);
                  bet.value = game.result;
                  hs.dispatchEvent(e);
                  as.dispatchEvent(e);
                  bet.dispatchEvent(e);
                  this.hideAVG();
            },
            hideAVG() {
                  this.showavg = false;
            },
            GoTo(teamid) {
                  var team = this.$functions.getTeam(teamid);
                  var url = this.$url.teamurl(team);
                  this.$router.push(url);
            },
            GoToVS(game) {
                  if (game.hometeamid > 0 && game.awayteamid > 0) {
                        var hteam = this.$functions.getTeam(game.hometeamid);
                        var ateam = this.$functions.getTeam(game.awayteamid);
                        var url = this.$url.teamvsurl(hteam, ateam);
                        this.$router.push(url);
                  }
                  return;
            },
            ViewTipsGame(game) {
                  var url = "/visa/matcher/" + game.id;
                  this.$router.push(url);
            }
      },
      mounted() {
            this.$store.dispatch("tipsg/getTipsGamesTips");
            this.loadscroll = true;
      },
      updated: function () {
            this.$nextTick(function () {
                  if (this.loadscroll) {
                        setTimeout(this.FixPageScroll, 350);
                        this.loadscroll = false;
                  }
            });
      }
};
</script>
